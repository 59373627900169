import { captureException } from "@sentry/browser";
import {
  EnhancedFormik,
  type EnhancedFormikExtraProps,
  type FormikConfigWithOverrides,
  FormikValues,
} from "@uplift-ltd/formik";

export default function UpliftFormik<
  Values extends FormikValues = FormikValues,
  // eslint-disable-next-line @typescript-eslint/ban-types
  ExtraProps extends EnhancedFormikExtraProps<Values> = {},
>(props: ExtraProps & FormikConfigWithOverrides<Values>) {
  return <EnhancedFormik<Values, ExtraProps> captureException={captureException} {...props} />;
}
