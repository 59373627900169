import { usePathname, useRouter } from "next/navigation";
import { useCallback } from "react";

type QueryParamValue = boolean | null | number | string | undefined;

export function useUpdateSearchParams() {
  const pathname = usePathname();
  const router = useRouter();
  const updateSearchParams = useCallback(
    (newParams: { [k: string]: QueryParamValue }, _options?: { scroll: boolean }) => {
      const newSearchParams = new URLSearchParams(window.location.search);
      Object.entries(newParams).forEach(([key, value]) => {
        if (value != null) {
          newSearchParams.set(key, value.toString());
        } else {
          newSearchParams.delete(key);
        }
      });
      const newSearch = newSearchParams.toString();
      const suffix = newSearch ? `?${newSearch}` : "";
      // TODO: handle scroll: true
      return router.replace(`${pathname}${suffix}`);
    },
    [pathname, router]
  );
  return updateSearchParams;
}
