import cx from "clsx";

const disabled = cx("disabled:cursor-not-allowed disabled:opacity-75");
const focus = cx("focus:outline-none focus:ring");

const input = cx(
  "block w-full appearance-none rounded border border-gray-400 bg-white px-3 py-2 leading-tight text-gray-700 shadow placeholder:text-gray-500",
  "dark:border-black dark:bg-gray-800 dark:text-white",
  focus,
  disabled
);

const checkbox = cx(
  "peer relative size-5 appearance-none border  border-gray-500 bg-white shadow",
  "dark:border-black dark:bg-gray-800",
  "checked:after:absolute checked:after:inset-[3px] checked:after:block checked:after:bg-orange-500 checked:after:content-['']"
);

export const formStyles = {
  checkbox: cx(checkbox, focus, disabled, "rounded", "checked:after:rounded-sm"),
  input,
  radio: cx(checkbox, focus, disabled, "rounded-full", "checked:after:rounded-full"),
  select: input,
  textarea: input,
};
