import cx from "clsx";
import { RegisterOffscreenElement } from "~/components/common/OffscreenElements";

export interface FormHelpTextProps {
  className?: string;
  error?: Error | null | string;
  touched?: boolean;
}

const FormHelpText = ({
  children,
  className,
  error,
  touched,
}: React.PropsWithChildren<FormHelpTextProps>) => {
  if (!(touched && error) && !children) {
    return null;
  }

  const showError = !!(touched && error);

  const content = (
    <p
      className={cx(
        "mt-1 text-xs first-letter:uppercase",
        className,
        showError ? "text-red-500" : "text-gray-500 dark:text-gray-600"
      )}
    >
      {(touched && (typeof error === "string" ? error : error?.message)) || children}
    </p>
  );

  if (touched && error) {
    return <RegisterOffscreenElement>{content}</RegisterOffscreenElement>;
  }

  return content;
};

export default FormHelpText;
