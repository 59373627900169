import { getUtmParams } from "~/helpers/utm";

const useGetUtmParams = () => {
  return () => {
    const searchParams = new URLSearchParams(window.location.search);
    return getUtmParams(Array.from(searchParams.entries()));
  };
};

export default useGetUtmParams;
