import { useEnhancedQuery } from "@uplift-ltd/apollo";
import { useUserContext } from "@uplift-ltd/use-user-context";
import { UserContactSubmissionDocument, UserContactSubmissionQuery } from "~/__generated__";
import { useUpdateSearchParams } from "./useUpdateSearchParams";
import { useWindowSearchParam } from "./useWindowSearchParam";

interface UseContactSubmissionLoaderProps {
  onCompleted?: (data: UserContactSubmissionQuery) => void;
}

export const useContactSubmissionLoader = ({
  onCompleted,
}: UseContactSubmissionLoaderProps = {}) => {
  const contactSubmissionId = useWindowSearchParam("contactSubmissionId", "");
  const updateSearchParams = useUpdateSearchParams();

  const { currentUser } = useUserContext();

  const { data, error, loading } = useEnhancedQuery(UserContactSubmissionDocument, {
    onCompleted,
    skip: !currentUser || !contactSubmissionId,
    variables: {
      contactSubmissionId: contactSubmissionId || "",
    },
  });

  const contactSubmission = data?.me?.contactSubmission;
  const contactSubmissionLoading = Boolean(contactSubmissionId && (loading || !(data || error)));

  const clearContactSubmissionLoader = () => {
    if (contactSubmissionId) {
      updateSearchParams({ contactSubmissionId: undefined });
    }
  };

  return {
    clearContactSubmissionLoader,
    contactSubmission,
    contactSubmissionLoading,
  };
};
