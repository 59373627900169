import cx from "clsx";
import React from "react";
import { formStyles } from "./Form.styles";

type TextareaElementProps = React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>;

type TextareaProps = Pick<
  TextareaElementProps,
  | "className"
  | "disabled"
  | "id"
  | "name"
  | "onBlur"
  | "onChange"
  | "placeholder"
  | "required"
  | "rows"
  | "value"
>;

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, ...otherProps }, ref) => {
    return <textarea className={cx(formStyles.textarea, className)} ref={ref} {...otherProps} />;
  }
);

Textarea.displayName = "Textarea";

export default Textarea;
