/* eslint-disable jsx-a11y/anchor-has-content */

import cx from "clsx";
import Link, { LinkProps } from "~/components/common/Link";
import { isExternalUrl } from "~/helpers/urls";

function TextLink({ className, href, ...rest }: LinkProps) {
  if (isExternalUrl(href)) {
    return (
      <a
        className={cx("text-blue-500 hover:text-blue-400", className)}
        href={href}
        rel="noopener noreferrer"
        target="_blank"
        {...rest}
      />
    );
  }
  return (
    <Link className={cx("text-blue-500 hover:text-blue-400", className)} href={href} {...rest} />
  );
}

export default TextLink;
