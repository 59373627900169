"use client";

import { showReportDialog } from "@sentry/browser";
import { FormikStatus } from "@uplift-ltd/formik";
import cx from "clsx";
import Alert from "~/components/common/Alert";
import { umami } from "~/helpers/umami";

interface FormStatusProps {
  className?: string;
  status: FormikStatus;
}

// TODO: handle when error is empty object from Persist
const FormStatus = ({
  className,
  status: { captureExceptionResult, formError, formSuccess },
}: FormStatusProps) => {
  if (formSuccess || formError) {
    if (formSuccess) {
      return (
        <Alert className={cx("up-form-status-success overflow-hidden", className)} theme="success">
          {formSuccess}
        </Alert>
      );
    }
    if (formError) {
      return (
        <Alert
          action={
            typeof captureExceptionResult === "string" && (
              <button
                className="float-right m-0 appearance-none border-0 bg-transparent p-0 text-blue-500"
                onClick={() => {
                  umami.trackEvent({ data: { label: "Report Error" }, name: "click" });
                  showReportDialog({ eventId: captureExceptionResult });
                }}
                type="button"
              >
                Report Error
              </button>
            )
          }
          className={cx("up-form-status-error overflow-hidden", className)}
          theme="error"
        >
          {formError instanceof Error ? formError.message : formError}
        </Alert>
      );
    }
  }
  return null;
};

export default FormStatus;
