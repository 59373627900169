import { S3FileAttachment, useUploadFiles } from "@uplift-ltd/file-uploads";
import { ensureError } from "@uplift-ltd/ts-helpers";
import { cva } from "class-variance-authority";
import { useEffect, useState } from "react";
import { GetSignedRequestDocument } from "~/__generated__";
import { FileList } from "~/components/common/FileAttachments/FileList";
import Input, { InputProps } from "~/components/common/Form/Input";
import Paragraph from "~/components/common/Paragraph";
import { GRAPHQL_AUTH_URL, GRAPHQL_UNAUTH_URL } from "~/constants/constants";
import { getRecaptchaToken } from "~/helpers/recaptcha";

const fileUploadStyles = cva(["relative flex h-24 items-center justify-center rounded-md"], {
  variants: {
    // TODO: separate
    state: {
      base: "border border-gray-300 bg-gray-100 shadow-sm dark:border-black dark:bg-gray-800",
      dragOver: "is-drag-over border border-yellow-500 bg-yellow-100 dark:bg-yellow-500/20",
      focus: "is-focus border-2 border-blue-500 ring-blue-500",
      loading: "is-loading border-2 border-green-500 bg-green-100 ring-green-500 dark:bg-green-900",
    },
  },
});

type FileUploadEvent = {
  target: {
    name: string;
    value: S3FileAttachment[];
  };
};

interface FileUploadProps {
  accept?: string;
  appLabel?: string;
  disabled?: boolean;
  grapheneId: string;
  isDraft?: boolean;
  label?: string;
  multiple?: boolean;
  name: string;
  onBlur?: InputProps["onBlur"];
  onChange?: (e: FileUploadEvent) => void;
  onError?: (err: Error) => void;
  onFocus?: InputProps["onFocus"];
  onLoading: (loading: boolean) => void;
  recaptchaAction?: string;
  uploadingLabel?: string;
  value?: S3FileAttachment[];
}

export function FileUpload({
  accept = "image/*,.doc,.docx,application/pdf",
  appLabel = "marketing",
  disabled,
  grapheneId,
  isDraft = true,
  label = "Drag and drop file or click to upload",
  multiple,
  name,
  onBlur,
  onChange,
  onError,
  onFocus,
  onLoading,
  recaptchaAction,
  uploadingLabel = "Uploading…",
  value,
}: FileUploadProps) {
  const { fileAttachments, loading, onRequestRemove, uploadFiles } = useUploadFiles({
    fileAttachments: value,
    signedRequestOptions: {
      options: {
        context: {
          uri: recaptchaAction ? GRAPHQL_UNAUTH_URL : GRAPHQL_AUTH_URL,
        },
      },
      query: GetSignedRequestDocument,
    },
  });
  const [dragOver, setDragOver] = useState(false);
  const [focus, setFocus] = useState(false);

  const numFiles = value?.length || 0;
  const showUpload = multiple || numFiles === 0;

  useEffect(() => {
    onChange?.({ target: { name, value: fileAttachments } });
  }, [fileAttachments, name, onChange]);

  useEffect(() => {
    onLoading(loading);
  }, [loading, onLoading]);

  return (
    <>
      {showUpload && (
        <div
          className={fileUploadStyles({
            // TODO: separate props
            // eslint-disable-next-line no-nested-ternary
            state: dragOver ? "dragOver" : focus ? "focus" : loading ? "loading" : "base",
          })}
        >
          <Paragraph className="text-sm text-gray-500 group-[.is-drag-over]:text-yellow-500 group-[.is-loading]:text-green-500">
            {loading ? uploadingLabel : label}
          </Paragraph>
          <Input
            accept={accept}
            className="absolute inset-0 h-24 cursor-pointer !opacity-0"
            disabled={disabled || loading}
            multiple={multiple}
            name={name}
            onBlur={e => {
              onBlur?.(e);
              setFocus(false);
            }}
            onChange={async e => {
              if (!e.target.files || e.target.files.length === 0) {
                return;
              }

              try {
                await Promise.allSettled(
                  Object.values(e.target.files).map(async file => {
                    // Uploading multiple files on the unauth schema requires a recaptcha
                    // which must be unique per file
                    const recaptchaToken = recaptchaAction
                      ? await getRecaptchaToken(recaptchaAction)
                      : null;

                    await uploadFiles({
                      appLabel,
                      files: [file],
                      grapheneId,
                      isDraft,
                      metadata: {},
                      recaptchaToken,
                    });
                  })
                );
              } catch (err) {
                if (onError) {
                  onError(ensureError(err, "Failed to upload file"));
                  return;
                }
                throw err;
              }
            }}
            onDragLeave={() => setDragOver(false)}
            onDragOver={() => setDragOver(true)}
            onDrop={() => setDragOver(false)}
            onFocus={e => {
              onFocus?.(e);
              setFocus(true);
            }}
            type="file"
          />
        </div>
      )}
      <FileList
        fileAttachments={value || []}
        onRequestRemove={fileAttachment => onRequestRemove(fileAttachment.id)}
      />
    </>
  );
}
