import { RECAPTCHA_SITE_KEY } from "~/constants/constants";

export function getRecaptchaToken(action: string): Promise<string> {
  return new Promise((resolve, reject) => {
    if (navigator.userAgent.includes("Playwright")) {
      resolve("");
      return;
    }
    grecaptcha.ready(async () => {
      grecaptcha.execute(RECAPTCHA_SITE_KEY as string, { action }).then(resolve, reject);
    });
  });
}
