import cx from "clsx";

export interface LabelProps {
  children: React.ReactNode;
  className?: string;
  htmlFor?: HTMLLabelElement["htmlFor"];
  required?: boolean;
  srOnly?: boolean;
}

const Label = ({ children, className, required, srOnly, ...otherProps }: LabelProps) => {
  return (
    <label
      className={cx(
        "mb-2 block font-medium text-gray-700 peer-disabled:cursor-not-allowed peer-disabled:opacity-75 dark:text-gray-400",
        className,
        {
          "sr-only": srOnly,
        }
      )}
      {...otherProps}
    >
      {children}
      {required && (
        <span aria-label="required" className="ml-1 text-red-500" title="required">
          *
        </span>
      )}
    </label>
  );
};

export default Label;
