import { cva } from "class-variance-authority";
import { Suspense } from "react";
import { TrackView } from "~/components/common/Analytics/TrackView";

const alertStyles = cva(
  ["relative mb-4 whitespace-pre-line break-words rounded border px-4 py-3"],
  {
    variants: {
      theme: {
        default:
          "border-gray-400 bg-gray-100 text-gray-700 dark:border-black dark:bg-black dark:text-gray-200",
        error:
          "border-red-400 bg-red-100 text-red-700 dark:border-red-900 dark:bg-red-900 dark:text-red-100",
        info: "border-blue-400 bg-blue-100 text-blue-700 dark:border-blue-900 dark:bg-blue-800 dark:text-blue-100",
        success:
          "border-green-400 bg-green-100 text-green-700 dark:border-green-900 dark:bg-green-900 dark:text-green-100",
      } satisfies Record<AlertTheme, string>,
    },
  }
);

type AlertTheme = "default" | "error" | "info" | "success";

interface AlertProps {
  action?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  theme?: AlertTheme;
}

const Alert = ({ action, children, className, theme = "default" }: AlertProps) => {
  return (
    <div className={alertStyles({ className, theme })} role="alert">
      {children}
      {action}
      <Suspense>
        <TrackView name={`alert-${theme}`}>{children}</TrackView>
      </Suspense>
    </div>
  );
};

export default Alert;
