"use client";

import { TrashIcon } from "@heroicons/react/24/outline";
import { S3FileAttachment } from "@uplift-ltd/file-uploads";
import cx from "clsx";
import { IconButton } from "~/components/common/Button";
import Heading from "~/components/common/Heading";
import TextLink from "~/components/common/TextLink";

interface FileListProps<FileAttachmentType> {
  className?: string;
  fileAttachments: FileAttachmentType[];
  onRequestRemove?: (fileAttachment: FileAttachmentType) => void;
}

export function FileList<FileAttachmentType extends S3FileAttachment = S3FileAttachment>({
  className,
  fileAttachments,
  onRequestRemove,
}: FileListProps<FileAttachmentType>) {
  return (
    <div className={cx("divide-y divide-gray-100 dark:divide-black", className)}>
      {fileAttachments.map(fileAttachment => {
        return (
          <div className="group flex justify-between py-3" key={fileAttachment.id}>
            <Heading tag="h5">
              <TextLink href={fileAttachment.url} rel="noopener noreferrer" target="_blank">
                {fileAttachment.name}
              </TextLink>
            </Heading>
            {onRequestRemove && (
              <IconButton
                className="opacity-0 transition-opacity group-hover:opacity-100"
                icon={<TrashIcon className="size-5 text-gray-500 hover:text-current" />}
                onClick={() => {
                  onRequestRemove(fileAttachment);
                }}
              />
            )}
          </div>
        );
      })}
    </div>
  );
}
