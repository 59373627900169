import cx from "clsx";
import React from "react";
import { formStyles } from "./Form.styles";

type InputElementProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export type InputProps = Pick<
  InputElementProps,
  | "accept"
  | "className"
  | "disabled"
  | "id"
  | "multiple"
  | "name"
  | "onBlur"
  | "onChange"
  | "onDragLeave"
  | "onDragOver"
  | "onDrop"
  | "onFocus"
  | "placeholder"
  | "required"
  | "type"
  | "value"
>;

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, ...otherProps }, ref) => {
    return <input className={cx(formStyles.input, className)} ref={ref} {...otherProps} />;
  }
);

Input.displayName = "Input";

export default Input;
