import { IS_PRODUCTION } from "~/constants/constants";

function env(name: string): string | undefined;
function env(name: string, defaultValue: string): string;
function env(name: string, defaultValue?: string) {
  return process.env[`UA_${name}`] || process.env[name] || defaultValue;
}

export const REDIS_URL = env("REDIS_URL", "redis://127.0.0.1:6379");
export const REDIS_PREFIX = env("REDIS_PREFIX", "upmd");

export const SENDGRID_KEY = env("SENDGRID_KEY");
export const SENDGRID_WEBHOOK_VERIFICATION_KEY = env("SENDGRID_WEBHOOK_VERIFICATION_KEY", "");
// eslint-disable-next-line no-nested-ternary
export const SENDGRID_ENV = IS_PRODUCTION
  ? "production"
  : process.env.NODE_ENV === "development"
    ? "development"
    : "staging";
export const SENDGRID_STAGING_WEBHOOK_URL = "https://staging.uplift.ltd/api/hooks/sendgrid/";
export const GA_ID = env("GA_ID", "UA-61653566-4");
export const SENTRY_DSN = env(
  "SENTRY_DSN",
  "https://dc1f1533e0a6466d80e2b13f706a7284@o396381.ingest.sentry.io/5250763"
);
export const HEALTHCHECKS_PING_URL = env(
  "HEALTHCHECKS_PING_URL",
  "https://healthchecks.uplift.ltd/ping/6ee6c8ca-d7a1-439a-b444-42dddbe88d93"
);
export const DEFAULT_HOST = env(
  "DEFAULT_HOST",
  process.env.VERCEL_URL ? `https://${process.env.VERCEL_URL}` : "https://www.uplift.ltd"
);
export const BOTS_HOST = env("BOTS_HOST", "http://127.0.0.1:3000");
export const UMAMI_TOKEN = env("UMAMI_TOKEN");

export const PIPEDRIVE_TOKEN = env("PIPEDRIVE_TOKEN");
export const PIPEDRIVE_BASE_URL = env(
  "PIPEDRIVE_BASE_URL",
  "https://uplift-ltd.pipedrive.com/deal/"
);

export const RECAPTCHA_SECRET_KEY = env("RECAPTCHA_SECRET_KEY");

export const SECURE_COOKIES = process.env.NODE_ENV === "production";

export const HMAC_SECRET = env("HMAC_SECRET", "");

export const CLIENT_CONTACT_RECIPIENTS = env(
  "CLIENT_CONTACT_RECIPIENTS",
  "Marius Craciunoiu<marius@uplift.ltd>, Paul Craciunoiu<paul@uplift.ltd>"
)
  .split(",")
  .map(recipient => recipient.trim());

export const CAREERS_CONTACT_RECIPIENTS = env(
  "CAREERS_CONTACT_RECIPIENTS",
  "Marius Craciunoiu<marius@uplift.ltd>, Paul Craciunoiu<paul@uplift.ltd>"
)
  .split(",")
  .map(recipient => recipient.trim());

export const CAREERS_ASSESSMENT_RECIPIENTS = env(
  "CAREERS_ASSESSMENT_RECIPIENTS",
  CAREERS_CONTACT_RECIPIENTS.join(",")
)
  .split(",")
  .map(recipient => recipient.trim());

// To user
export const CLIENT_FROM = env("CLIENT_FROM", "Uplift.ltd <sales@uplift.ltd>");
export const CLIENT_REPLY_TO = env("CLIENT_REPLY_TO", "Uplift.ltd <sales@uplift.ltd>");

export const CAREERS_FROM = env("CAREERS_FROM", "Uplift.ltd <careers@uplift.ltd>");
export const CAREERS_REPLY_TO = env("CAREERS_REPLY_TO", "Uplift.ltd <careers@uplift.ltd>");
export const CAREERS_CANDIDATE_SCHEDULE_TEAM_MEMBER_NAME = env(
  "CAREERS_CANDIDATE_SCHEDULE_TEAM_MEMBER_NAME",
  "Mats Andersson"
);
export const CAREERS_CANDIDATE_SCHEDULE_LINK = env(
  "CAREERS_CANDIDATE_SCHEDULE_LINK",
  "https://calendly.com/matsuplift30min/30min"
);
export const CAREERS_CANDIDATE_CODING_EXERICSE_TEAM_MEMBER_NAME = env(
  "CAREERS_CANDIDATE_CODING_EXERICSE_TEAM_MEMBER_NAME",
  "Kristin"
);

// To uplift
export const NOTIFICATION_FROM = env("NOTIFICATION_FROM", "Uplift.ltd <notifications@uplift.ltd>");

// Pipedrive Fields
export const PIPEDRIVE_SOURCE_PERSON_FIELD_ID = "37cfaed2dcd09aa63533c4efc2b78536b40562ae";
export const PIPEDRIVE_REFERRAL_DEAL_FIELD_ID = "e71a02c34973ad43b16c06f066a6942d9eb3faa0";

// Pipedrive Stages
export const PIPEDRIVE_STAGE_CLIENT_CONTACT_MADE_ID = 1;
// NOTE: if adding a candidate stage update getVisibleTo in addToPipedrive
export const PIPEDRIVE_STAGE_CANDIDATE_APPLIED_ID = 6;
export const PIPEDRIVE_STAGE_CANDIDATE_ASSESSMENT_COMPLETED_ID = 14;
export const PIPEDRIVE_STAGE_CANDIDATE_CODING_EXERCISE_ID = 8;

// Pipedrive Visibility
export const PIPEDRIVE_VISIBLE_TO_EVERYONE = 3;

// Sendgrid Lists
export const SENDGRID_CLIENTS_LIST_ID = "eb8363d4-7335-4764-9b5a-04e9219542a3";
export const SENDGRID_BLOG_LIST_ID = "7d5b7654-c684-4ab1-9741-22394802f057";

// Sendgrid Templates
export const SENDGRID_CLIENT_CONTACT_RESPONSE_TEMPLATE_ID = "d-6c249a4c31024504803fd8712a7ebe5c";
export const SENDGRID_CLIENT_CONTACT_REMINDER_RESPONSE_TEMPLATE_ID =
  "d-05acfa9b5f154292b28f1012bb256af5";
export const SENDGRID_CLIENT_CONTACT_NOTIFICATION_TEMPLATE_ID =
  "d-29855bd71b3c44bebeac6cfa6460b6b4";
export const SENDGRID_CANDIDATE_CONTACT_RESPONSE_TEMPLATE_ID = "d-e8419345870c4d9f9f2596331b28dbbd";
export const SENDGRID_CANDIDATE_CONTACT_REMINDER_RESPONSE_TEMPLATE_ID =
  "d-8ebf8cb03f434b4583e7074bec9ecebf";
export const SENDGRID_CANDIDATE_CONTACT_NOTIFICATION_TEMPLATE_ID =
  "d-6265390558834a7190915f3086ec3ae2";
export const SENDGRID_ASSESSMENT_START_TEMPLATE_ID = "d-88573ad8b73b460598e454d80160d1ba";
export const SENDGRID_ASSESSMENT_RESULT_TEMPLATE_ID = "d-a89b19e37d6743a5808ba0a724a87397";
export const SENDGRID_ASSESSMENT_NOTIFY_PASSED_TEMPLATE_ID = "d-67be9cb338bd4e1bb9887feb66e66735";
export const SENDGRID_ASSESSMENT_NOTIFY_FAILED_TEMPLATE_ID = "d-335cb76df8e242269cebc225184156dc";
export const SENDGRID_ASSESSMENT_REMINDER_TEMPLATE_ID = "d-7f5f551d19dc4eb4bc55bfe4df684e46";
export const SENDGRID_CODING_EXERCISE_TEMPLATE_ID = "d-c701ed978bb84060b81c2a47a3fb5197";
export const SENDGRID_MAGIC_LINK_TEMPLATE_ID = "d-55d9b1ad9b14403eae0ca7e7d68fbaa0";

export const AUTH_COOKIE_NAME = "upjwt";

export const GITHUB_INTERVIEW_ACCESS_TOKEN = env("GITHUB_INTERVIEW_ACCESS_TOKEN");
export const GITHUB_INTERVIEW_COLLABORATORS = env(
  "GITHUB_INTERVIEW_COLLABORATORS",
  "mariusc23, pcraciunoiu, camflan, MatsAndersson67"
)
  .split(",")
  .map(recipient => recipient.trim());

export const GITHUB_INTERVIEW_INTERVIEW_REPO_OWNER = env(
  "GITHUB_INTERVIEW_INTERVIEW_REPO_OWNER",
  "uplift-interview"
);
export const GITHUB_INTERVIEW_INTERVIEW_REPO = env(
  "GITHUB_INTERVIEW_INTERVIEW_REPO",
  "uplift-interview"
);
export const GITHUB_INTERVIEW_CANDIDATE_REPO_PREFIX = `${GITHUB_INTERVIEW_INTERVIEW_REPO}-`;
export const GITHUB_INTERVIEW_REPO_MAIN_BRANCH = env("GITHUB_INTERVIEW_REPO_MAIN_BRANCH", "main");
export const GITHUB_INTERVIEW_INTERVIEW_REPO_CHECKS_WORKFLOW = env(
  "GITHUB_INTERVIEW_INTERVIEW_REPO_CHECKS_WORKFLOW",
  "post-template-clone.yml"
);

export const GITHUB_REPOSITORY_WEBHOOK_SECRET = env("GITHUB_REPOSITORY_WEBHOOK_SECRET");

export const DEFAULT_PAGINATION_LIMIT = 50;
export const MAX_PAGINATION_LIMIT = 100;

export const PROXYCHECK_API_KEY = env("PROXYCHECK_API_KEY");

// Utm cookies
export const UTM_COOKIE_EXPIRY_PERIOD_DAYS = 30;

// e2e
export const E2E_HOST = env("E2E_HOST", "http://127.0.0.1:3000");
export const E2E_CAREER = env("E2E_CAREER", "fullstack");
export const E2E_TEST_EMAIL = env("E2E_TEST_EMAIL", "test-candidate@uplift.ltd");
export const E2E_ASSESSMENT_LINK = env("E2E_ASSESSMENT_LINK");

// s3
export const AWS_ACCESS_KEY_ID = env("AWS_ACCESS_KEY_ID", "");
export const AWS_SECRET_ACCESS_KEY = env("AWS_SECRET_ACCESS_KEY", "");
export const AWS_DEFAULT_REGION = env("AWS_DEFAULT_REGION", "nyc3");
export const S3_BUCKET_NAME = env("S3_BUCKET_NAME", "uplift-ltd-local");
export const S3_ENDPOINT = env("S3_ENDPOINT", "https://nyc3.digitaloceanspaces.com");

export const DEFAULT_CONTACT_SUBMISSION_GLOBAL_ID = "Q29udGFjdFN1Ym1pc3Npb246MA"; // ContactSubmission:0

export const ONE_HOUR_IN_SECONDS = 3600;
export const ONE_DAY_IN_SECONDS = 86400;

export const SENDGRID_MAIL_SEND_PRINTS_TO_CONSOLE = env("SENDGRID_MAIL_SEND_PRINTS_TO_CONSOLE");

export const PUSHER_APP_ID = env("PUSHER_APP_ID", "uplift-marketing");
export const PUSHER_SECRET = env("PUSHER_SECRET", "");
