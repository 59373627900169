export function scrollToElement(el: HTMLElement | null) {
  if (!el) {
    return;
  }
  if (window.pageYOffset > el.offsetTop) {
    window.scrollTo({
      behavior: "smooth",
      top: el.offsetTop - 24,
    });
  }
}
