export const UTM_KEY_REGEX = /(utm_.+|source|ref)/;

export const getUtmParams = (entries: [string, string][]): Record<string, string> =>
  entries
    .filter(([key]) => key.match(UTM_KEY_REGEX))
    .reduce((prevParams, [key, value]) => {
      return {
        ...prevParams,
        [key]: value,
      };
    }, {});

export type UtmParams = {
  ref: string;
  source: string;
  utm_campaign: string;
  utm_content: string;
  utm_medium: string;
  utm_source: string;
  utm_term: string;
};
