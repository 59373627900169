import {
  DocumentNode,
  EnhancedQueryResult,
  OperationVariables,
  QueryHookOptions,
  TypedDocumentNode,
  useEnhancedQuery,
} from "@uplift-ltd/apollo";
import { GRAPHQL_UNAUTH_URL } from "~/constants/constants";

export function usePublicQuery<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options: QueryHookOptions<TData, TVariables> = {}
): EnhancedQueryResult<TData, TVariables> {
  const result = useEnhancedQuery(query, {
    ...options,
    context: {
      ...options.context,
      uri: GRAPHQL_UNAUTH_URL,
    },
  });

  return {
    ...result,
  };
}
