import {
  ApolloCache,
  DocumentNode,
  EnhancedDefaultContext,
  MutationHookOptions,
  MutationTuple,
  OperationVariables,
  TypedDocumentNode,
  useEnhancedMutation,
} from "@uplift-ltd/apollo";
import { GRAPHQL_UNAUTH_URL } from "~/constants/constants";

export function usePublicMutation<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TData = any,
  TVariables extends OperationVariables = OperationVariables,
  TContext extends EnhancedDefaultContext = EnhancedDefaultContext,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TCache extends ApolloCache<any> = ApolloCache<any>,
>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options: MutationHookOptions<TData, TVariables, TContext, TCache> = {}
): MutationTuple<TData, TVariables, TContext, TCache> {
  return useEnhancedMutation(mutation, {
    ...options,
    context: {
      ...options.context,
      uri: GRAPHQL_UNAUTH_URL,
    } as TContext,
  });
}
