import { useEffect } from "react";
import { StatusDocument } from "~/__generated__";
import { captureException, captureMessage } from "~/helpers/sentry";
import { usePublicQuery } from "./usePublicQuery";

export const useStatus = () => {
  const { data, error } = usePublicQuery(StatusDocument);

  useEffect(() => {
    if (error) {
      captureException(error);
    }
    if (data && data.status && Object.values(data.status).some(statum => statum === false)) {
      captureMessage("Status check failed", { extra: { data, error } });
    }
  }, [data, error]);

  return { status: data?.status, statusError: error };
};
