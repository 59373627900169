import React from "react";

export interface FormGroupProps {
  children: React.ReactNode;
  className?: string;
}

const FormGroup = ({ children, className, ...props }: FormGroupProps) => {
  return (
    <div className={className} {...props}>
      {children}
    </div>
  );
};

export default FormGroup;
